import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";

function Navbar() {
  // State to manage the visibility of the navbar menu on mobile
  const [isOpen, setIsOpen] = useState(false);

  // Function to toggle the menu visibility
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-darkBlue fixed z-50 w-screen border-b-2 border-lightBlue">
      <button
        onClick={toggleMenu}
        data-collapse-toggle="navbar-default"
        type="button"
        className={`${
          isOpen ? "block" : "hidden"
        } absolute  top-4 right-4 text-sm text-lightBlue rounded-lg md:hidden hover:bg-gray-100 focus:outline-none`}
        aria-controls="navbar-default"
        aria-expanded={false}
      >
        <span className="sr-only">Close main menu</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="size-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
      </button>
      <div className="w-full flex items-center md:px-16 justify-between p-4">
        <div className={`${isOpen ? "hidden" : "block"}`}>
          <a
            href="https://oharaslanding.com/"
            className="flex xs:justify-start items-start"
          >
            <div
              className={`xs:align-top md:self-center text-3xl arizona font-thin whitespace-nowrap text-lightBlue`}
            >
              O'Hara's Landing
            </div>
          </a>
          <div
            className={`${
              isOpen ? "hidden" : "block"
            } text-mediumBlue font-bold mt-2`}
          >
            Twin Lakes, CT
          </div>
        </div>

        <button
          onClick={toggleMenu}
          data-collapse-toggle="navbar-default"
          type="button"
          className={`${
            isOpen ? "hidden" : "block"
          } inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-lightBlue rounded-lg md:hidden hover:bg-gray-100 focus:outline-none`}
          aria-controls="navbar-default"
          aria-expanded={false}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            fill="none"
            viewBox="0 0 17 14"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div
          className={`${isOpen ? "block" : "hidden"} md:block md:w-auto`}
          id="navbar-default"
        >
          <ul className="lg:flex justify-end">
            <li>
              <Link
                smooth={true}
                duration={500}
                to="about"
                className="block px-3 py-2 rounded-md text-base font-bold text-lightBlue hover:bg-gray-100 hover:text-darkBlue"
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                smooth={true}
                duration={500}
                to="salesandservice"
                className="block px-3 py-2 rounded-md text-base font-bold text-lightBlue hover:bg-gray-100 hover:text-darkBlue"
              >
                Sales & Service
              </Link>
            </li>
            <li>
              <Link
                smooth={true}
                duration={500}
                to="rentals"
                className="block px-3 py-2 rounded-md text-base font-bold text-lightBlue hover:bg-gray-100 hover:text-darkBlue"
              >
                Boat Rentals
              </Link>
            </li>
            <li>
              <Link
                smooth={true}
                duration={500}
                to="restaurant"
                className="block px-3 py-2 rounded-md text-base font-bold text-lightBlue hover:bg-gray-100 hover:text-darkBlue"
              >
                Mary's Cafe
              </Link>
            </li>
            <li>
              <Link
                smooth={true}
                duration={500}
                to="gallery"
                className="block px-3 py-2 rounded-md text-base font-bold text-lightBlue hover:bg-gray-100 hover:text-darkBlue"
              >
                Gallery
              </Link>
            </li>
            <li>
              <Link
                smooth={true}
                duration={500}
                to="contact"
                className="block px-3 py-2 rounded-md text-base font-bold text-lightBlue hover:bg-gray-100 hover:text-darkBlue"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
