import React, { useEffect, useState } from "react";
import {
  query,
  orderBy,
  collection,
  limit,
  getDocs,
  getFirestore,
} from "firebase/firestore";

import {
  weatherCodeFullDayDescriptions,
  weatherCodeFullDayIcons,
} from "../screens/Weather";

import { db } from "../App";

const WeatherWidgetSmall = (forecast) => {
 
  const options = {
    weekday: "long",
  };
 
  return (
    <div class="flex flex-col  rounded-xl w-full border-mediumBlue   p-4  ">
      <div class="text-sm font-semibold text-sunYellow">
        {new Date(forecast?.forecast?.sunriseTime).toLocaleDateString(
          "en-US",
          options
        )}
      </div>
      <div class="text-6xl self-center inline-flex items-center justify-center rounded-lg text-white h-16 ">
        <i
          className={`${
            weatherCodeFullDayIcons[forecast?.forecast?.weatherCodeFullDay]
          } text-4xl mr-1 text-lightBlue`}
        ></i>
      </div>
      <div className="text-white w-full text-xl text-center font-semibold">
        {weatherCodeFullDayDescriptions[forecast?.forecast?.weatherCodeFullDay]}
      </div>

      <div className="flex font-medium justify-center text-gray-700 my-2 text-center">
        <i className="wi wi-rain text-sm mr-1 text-white"></i>
 
        <div className=" text-sm text-white">
          {forecast?.forecast?.precipitationProbability}%
        </div>
      </div>

      <div class="flex flex-row  justify-center gap-3">
        <div className="flex items-center justify-center ">
          <span className="items-center">
            <i className="wi wi-direction-down grow text-xl mr-2 text-white"></i>
          </span>
          <span class="text-sm font-medium items-center mt-1 text-white">
            {Math.round(forecast?.forecast?.temperatureMin)}°F
          </span>
        </div>
        <div className="flex items-center justify-start ">
          <span className=" items-center">
            <i className="wi wi-direction-up text-xl mr-2 text-white4"></i>
          </span>

          <span class="text-sm items-center font-medium mt-1 text-white ">
            {Math.round(forecast?.forecast?.temperatureMax)}°F
          </span>
        </div>
      </div>
    </div>
  );
};

export default WeatherWidgetSmall;
