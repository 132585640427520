import React, { useState, useEffect } from "react";
import InfoCard from "../components/InfoCard";
import BoatLaunch from "../images/noun-boat-launch-200-3B6BF9.svg";
import Rentals from "../images/noun-lake-6809448.svg";
import Cafe from "../images/noun-cafe-6974749.svg";
import Service from "../images/noun-propeller-6549197.svg";
import { Element } from "react-scroll";
import WeatherWidget from "../components/WeatherWidget";
import WeatherWidgetSmall from "../components/WeatherWidgetSmall";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../App";
import WeatherWidgetHour from "../components/WeatherWidgetHour";

export const weatherCodeFullDayDescriptions = {
  0: "Unknown",
  1000: "Clear, Sunny",
  1100: "Mostly Clear",
  1101: "Partly Cloudy",
  1102: "Mostly Cloudy",
  1001: "Cloudy",
  1103: "Partly Cloudy and Mostly Clear",
  2100: "Light Fog",
  2101: "Mostly Clear and Light Fog",
  2102: "Partly Cloudy and Light Fog",
  2103: "Mostly Cloudy and Light Fog",
  2106: "Mostly Clear and Fog",
  2107: "Partly Cloudy and Fog",
  2108: "Mostly Cloudy and Fog",
  2000: "Fog",
  4204: "Partly Cloudy and Drizzle",
  4203: "Mostly Clear and Drizzle",
  4205: "Mostly Cloudy and Drizzle",
  4000: "Drizzle",
  4200: "Light Rain",
  4213: "Mostly Clear and Light Rain",
  4214: "Partly Cloudy and Light Rain",
  4215: "Mostly Cloudy and Light Rain",
  4209: "Mostly Clear and Rain",
  4208: "Partly Cloudy and Rain",
  4210: "Mostly Cloudy and Rain",
  4001: "Rain",
  4211: "Mostly Clear and Heavy Rain",
  4202: "Partly Cloudy and Heavy Rain",
  4212: "Mostly Cloudy and Heavy Rain",
  4201: "Heavy Rain",
  5115: "Mostly Clear and Flurries",
  5116: "Partly Cloudy and Flurries",
  5117: "Mostly Cloudy and Flurries",
  5001: "Flurries",
  5100: "Light Snow",
  5102: "Mostly Clear and Light Snow",
  5103: "Partly Cloudy and Light Snow",
  5104: "Mostly Cloudy and Light Snow",
  5122: "Drizzle and Light Snow",
  5105: "Mostly Clear and Snow",
  5106: "Partly Cloudy and Snow",
  5107: "Mostly Cloudy and Snow",
  5000: "Snow",
  5101: "Heavy Snow",
  5119: "Mostly Clear and Heavy Snow",
  5120: "Partly Cloudy and Heavy Snow",
  5121: "Mostly Cloudy and Heavy Snow",
  5110: "Drizzle and Snow",
  5108: "Rain and Snow",
  5114: "Snow and Freezing Rain",
  5112: "Snow and Ice Pellets",
  6000: "Freezing Drizzle",
  6003: "Mostly Clear and Freezing drizzle",
  6002: "Partly Cloudy and Freezing drizzle",
  6004: "Mostly Cloudy and Freezing drizzle",
  6204: "Drizzle and Freezing Drizzle",
  6206: "Light Rain and Freezing Drizzle",
  6205: "Mostly Clear and Light Freezing Rain",
  6203: "Partly Cloudy and Light Freezing Rain",
  6209: "Mostly Cloudy and Light Freezing Rain",
  6200: "Light Freezing Rain",
  6213: "Mostly Clear and Freezing Rain",
  6214: "Partly Cloudy and Freezing Rain",
  6215: "Mostly Cloudy and Freezing Rain",
  6001: "Freezing Rain",
  6212: "Drizzle and Freezing Rain",
  6220: "Light Rain and Freezing Rain",
  6222: "Rain and Freezing Rain",
  6207: "Mostly Clear and Heavy Freezing Rain",
  6202: "Partly Cloudy and Heavy Freezing Rain",
  6208: "Mostly Cloudy and Heavy Freezing Rain",
  6201: "Heavy Freezing Rain",
  7110: "Mostly Clear and Light Ice Pellets",
  7111: "Partly Cloudy and Light Ice Pellets",
  7112: "Mostly Cloudy and Light Ice Pellets",
  7102: "Light Ice Pellets",
  7108: "Mostly Clear and Ice Pellets",
  7107: "Partly Cloudy and Ice Pellets",
  7109: "Mostly Cloudy and Ice Pellets",
  7000: "Ice Pellets",
  7105: "Drizzle and Ice Pellets",
  7106: "Freezing Rain and Ice Pellets",
  7115: "Light Rain and Ice Pellets",
  7117: "Rain and Ice Pellets",
  7103: "Freezing Rain and Heavy Ice Pellets",
  7113: "Mostly Clear and Heavy Ice Pellets",
  7114: "Partly Cloudy and Heavy Ice Pellets",
  7116: "Mostly Cloudy and Heavy Ice Pellets",
  7101: "Heavy Ice Pellets",
  8001: "Mostly Clear and Thunderstorm",
  8003: "Partly Cloudy and Thunderstorm",
  8002: "Mostly Cloudy and Thunderstorm",
  8000: "Thunderstorm",
};

export const weatherCodeFullDayIcons = {
  0: "wi wi-na",
  1000: "wi wi-day-sunny",
  1100: "wi wi-day-sunny-overcast",
  1101: "wi wi-day-cloudy",
  1102: "wi wi-cloudy",
  1001: "wi wi-cloudy",
  1103: "wi wi-day-sunny-overcast",
  2100: "wi wi-fog",
  2101: "wi wi-day-fog",
  2102: "wi wi-day-fog",
  2103: "wi wi-fog",
  2106: "wi wi-day-fog",
  2107: "wi wi-day-fog",
  2108: "wi wi-fog",
  2000: "wi wi-fog",
  4204: "wi wi-day-sprinkle",
  4203: "wi wi-day-sprinkle",
  4205: "wi wi-day-sprinkle",
  4000: "wi wi-sprinkle",
  4200: "wi wi-rain-mix",
  4213: "wi wi-day-rain-mix",
  4214: "wi wi-day-rain-mix",
  4215: "wi wi-day-rain-mix",
  4209: "wi wi-day-rain",
  4208: "wi wi-day-rain",
  4210: "wi wi-day-rain",
  4001: "wi wi-rain",
  4211: "wi wi-day-rain-wind",
  4202: "wi wi-day-rain-wind",
  4212: "wi wi-day-rain-wind",
  4201: "wi wi-rain-wind",
  5115: "wi wi-day-snow-wind",
  5116: "wi wi-day-snow-wind",
  5117: "wi wi-day-snow-wind",
  5001: "wi wi-snowflake-cold",
  5100: "wi wi-day-snow",
  5102: "wi wi-day-snow",
  5103: "wi wi-day-snow",
  5104: "wi wi-day-snow",
  5122: "wi wi-sleet",
  5105: "wi wi-day-snow",
  5106: "wi wi-day-snow",
  5107: "wi wi-day-snow",
  5000: "wi wi-snow",
  5101: "wi wi-snow",
  5119: "wi wi-day-snow",
  5120: "wi wi-day-snow",
  5121: "wi wi-day-snow",
  5110: "wi wi-sleet",
  5108: "wi wi-rain-mix",
  5114: "wi wi-rain-mix",
  5112: "wi wi-rain-mix",
  6000: "wi wi-sleet",
  6003: "wi wi-day-sleet",
  6002: "wi wi-day-sleet",
  6004: "wi wi-day-sleet",
  6204: "wi wi-sleet",
  6206: "wi wi-sleet",
  6205: "wi wi-day-sleet-storm",
  6203: "wi wi-day-sleet-storm",
  6209: "wi wi-day-sleet-storm",
  6200: "wi wi-sleet-storm",
  6213: "wi wi-day-sleet-storm",
  6214: "wi wi-day-sleet-storm",
  6215: "wi wi-day-sleet-storm",
  6001: "wi wi-sleet-storm",
  6212: "wi wi-sleet",
  6220: "wi wi-sleet",
  6222: "wi wi-sleet",
  6207: "wi wi-day-sleet-storm",
  6202: "wi wi-day-sleet-storm",
  6208: "wi wi-day-sleet-storm",
  6201: "wi wi-sleet-storm",
  7110: "wi wi-day-sleet-storm",
  7111: "wi wi-day-sleet-storm",
  7112: "wi wi-day-sleet-storm",
  7102: "wi wi-sleet-storm",
  7108: "wi wi-sleet-storm",
  7107: "wi wi-sleet-storm",
  7109: "wi wi-sleet-storm",
  7000: "wi wi-hail",
  7105: "wi wi-hail",
  7106: "wi wi-hail",
  7115: "wi wi-hail",
  7117: "wi wi-hail",
  7103: "wi wi-hail",
  7113: "wi wi-day-hail",
  7114: "wi wi-day-hail",
  7116: "wi wi-day-hail",
  7101: "wi wi-hail",
  8001: "wi wi-day-thunderstorm",
  8003: "wi wi-day-thunderstorm",
  8002: "wi wi-day-thunderstorm",
  8000: "wi wi-thunderstorm",
};

const options = {
  weekday: "long",
  day: "numeric",
  month: "long",
  year: "numeric",
};

const Weather = () => {
  const [weatherData, setWeatherData] = useState(null);
  const [dayData, setDayData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getForecast = async () => {
    const docRef = doc(db, "forecasts", "current");
    const docSnap = await getDoc(docRef);

    setWeatherData(docSnap.data().data);
  };

  const getDaily = async () => {
    const docRef = doc(db, "forecasts", "hourly");
    const docSnap = await getDoc(docRef);
    setDayData(docSnap.data().data);
  };

  useEffect(() => {
    getForecast();
    getDaily();
  }, []);

  const options = {
    weekday: "long", // Display the full name of the day
    month: "long", // Display the full name of the month
    day: "numeric", // Display the day of the month as a number
  };

  return (
    <Element name="weather">
      <div className=" flex bg-white">
        <div className="flex xs:flex-col md:flex-row justify-center w-screen xs:px-4 py-4 md:px-16">
          <WeatherWidget
            forecast={weatherData?.timelines[0].intervals[0].values}
          />

          <div className="text-mediumBlue md:flex justify-center  xs:hidden items-center w-full h-full text-3xl ml-16">
            <div className="">
              <div className="mb-6 text-2xl text-mediumBlue font-semibold">
                Today
                <div class="text-sm text-gray-500">
                  {new Date().toLocaleDateString("en-US", options)}
                </div>
              </div>
              <div className="flex gap-4">
                {dayData?.timelines[0]?.intervals.map((hour) => {
                  return <WeatherWidgetHour forecast={hour} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid xs:grid-cols-1 md:grid-cols-5 gap-4  bg-darkBlue p-4">
        {weatherData?.timelines[0].intervals.map((interval) => {
          return <WeatherWidgetSmall forecast={interval.values} />;
        })}
      </div>
    </Element>
  );
};

export default Weather;
