import React, { useEffect, useState } from "react";
import {
  query,
  orderBy,
  collection,
  limit,
  getDocs,
  getFirestore,
} from "firebase/firestore";

import {
  weatherCodeFullDayDescriptions,
  weatherCodeFullDayIcons,
} from "../screens/Weather";

import { db } from "../App";

const WeatherWidget = (forecast) => {
  const [weatherData, setWeatherData] = useState();
  const getWeatherData = async () => {
    const weatherRef = collection(db, "weatherData");

    const q = query(weatherRef, orderBy("date", "desc"), limit(1));

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setWeatherData(doc.data());
    });
  };

  useEffect(() => {
    getWeatherData();
  }, []);

  return (
    <div class="flex flex-col bg-white justify-center min-w-72 md:border-r-2 gap-1 md:pr-12">
      <div class="text-6xl self-center flex items-center rounded-lg text-mediumBlue my-2 h-24 w-24">
        <i
          className={`${
            weatherCodeFullDayIcons[forecast?.forecast?.weatherCodeFullDay]
          } text-7xl mr-1 text-mediumBlue`}
        ></i>
      </div>
      <div className="text-mediumBlue w-full text-center font-semibold">
        {weatherCodeFullDayDescriptions[forecast?.forecast?.weatherCodeFullDay]}
      </div>
      {forecast?.forecast?.precipitationProbability > 10 && (
        <div className="flex font-medium justify-center text-gray-700 my-2 text-center">
          <i className="wi wi-rain text-sm mr-1 text-mediumBlue"></i>

          <div className="text-mediumBlue">
            {forecast?.forecast?.precipitationProbability}%
          </div>
        </div>
      )}
      <div class="flex flex-row items-center justify-center ">
        <div class="font-medium text-mediumBlue text-6xl">
          {Math.round(weatherData?.tempf)}°
        </div>
        <div class="flex flex-col items-start ml-6">
          <div className="flex items-center justify-start text-start">
            <span className=" ">
              <i className="wi wi-direction-up text-xl mr-2 text-mediumBlue"></i>
            </span>

            <span class="text-sm font-medium text-gray-700 ">
              {Math.round(forecast?.forecast?.temperatureMax)}°F
            </span>
          </div>
          <div className="flex items-start justify-center text-start ">
            <span className="">
              <i className="wi wi-direction-down grow text-xl mr-2 text-mediumBlue"></i>
            </span>
            <span class="text-sm font-medium text-gray-700">
              {Math.round(forecast?.forecast?.temperatureMin)}°F
            </span>
          </div>
        </div>
      </div>
      <div class="flex flex-row justify-between mt-2">
        <div class="flex flex-col items-center">
          <div class="font-medium text-mediumBlue text-sm">Wind</div>
          <div class="text-sm font-medium text-gray-500">
            {weatherData?.windspeedmph}mph
          </div>
        </div>
        <div class="flex flex-col items-center">
          <div class="font-medium text-mediumBlue text-sm">Humidity</div>
          <div class="text-sm font-medium text-gray-500">
            {weatherData?.humidity}%
          </div>
        </div>
        <div class="flex flex-col items-center">
          <div class="font-medium text-mediumBlue text-sm">Visibility</div>
          <div class="text-sm font-medium text-gray-500">
            {Math.round(forecast?.forecast?.visibility)}mi
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeatherWidget;
