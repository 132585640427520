import React from "react";

import Header from "./screens/Header"
import Navbar from "./screens/Navbar";
import SalesAndService from "./screens/SalesAndService";
import Services from "./screens/Services";
import FamilyOwned from "./screens/FamilyOwned";
import Rentals from "./screens/Rentals";
import Gallery from "./screens/Gallery";
import Restaurant from "./screens/Restaurant";
import Map from "./screens/Map";
import Footer from "./screens/Footer";
import Weather from "./screens/Weather";

const Home = () => {
  return (
    <div className="App">
      <Navbar />
      <Header />
      <Services />
      <Weather />
      <FamilyOwned />
      <SalesAndService />
      <Rentals />
      <Restaurant />
      <Gallery />
      <Map />
      <Footer />
    </div>
  );
};

export default Home;
